import MutationState from '@/interfaces/MutationState';
import CustomerAuthService from '@/msal/customerAuthService';
import Container from '@/injection/Container';
import CustomerAddress from '@/interfaces/Address';
import { AuthService } from '@/msal/AuthService';
import { DrawerState, MenuItem } from '@/interfaces/DrawerState';

const authService: AuthService = Container.resolve<AuthService>(CustomerAuthService);

const menuItems: MenuItem[] = [
  { Title: 'Dashboard', Route: 'Business', Icon: 'mdi-view-dashboard' },
  { Title: 'Business Details', Route: 'BusinessEdit', Icon: 'mdi-create' },
  { Title: 'Services', Route: 'BusinessServicesEdit', Icon: 'mdi-apps' },
  { Title: 'Receipts', Route: 'BusinessReceipts', Icon: 'mdi-receipt' },
  { Title: 'Help', Route: 'BusinessHelp', Icon: 'mdi-help-box' }
];

const drawerState: DrawerState = {
  MenuItems: menuItems,
  IsOpen: true
};

const startingState: MutationState = {
  status: '',
  userToken: authService.getAccount()?.idToken?.value ?? '',
  userAccount: authService.getAccount() ?? undefined,
  address: new CustomerAddress(),
  paymentToken: undefined,
  servicePrice: undefined,
  drawer: drawerState
};

export default startingState;
