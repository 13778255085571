import Vue from 'vue';
import Vuex from 'vuex';
import startingState from './state';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  state: startingState,
  mutations: mutations,
  actions: actions,
  modules: modules,
  getters: getters
});
