import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import 'reflect-metadata';
import CustomerAuthService from './msal/customerAuthService';
import Container from './injection/Container';
import { AuthService } from './msal/AuthService';

// const authService: AuthService = Container.resolve<AuthService>(CustomerAuthService);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
