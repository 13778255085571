


























import { Component, Vue } from 'vue-property-decorator';
import PayCard from '@/components/PayCard.vue';
import { injectable, Container } from 'inversify';
import { AuthError } from 'msal';
import { AuthService } from '../msal/AuthService';
import container from '../injection/Container';

@injectable()
@Component({
  name: 'PayAction',
  components: {
    PayCard
  }
})
export default class PayAction extends Vue {
  public authService: AuthService;

  private diContainer: Container = container;

  constructor() {
    super();
    this.authService = this.diContainer.getNamed<AuthService>('AuthService', 'customer');
  }

  get account() {
    return this.$store.state.userAccount;
  }

  async login(): Promise<void> {
    try {
      const response = await this.authService.login();
      this.$store.commit('authSuccess', this.authService.getAccount());
      this.$router.push('pay');
      //   console.log(`AccessToken ${response}`);
    } catch (e) {
      if (e instanceof AuthError) {
        // console.log(e);
      } else {
        throw e;
      }
    }
  }

  logout(): void {
    this.authService.logout();
  }
}
