import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Account } from 'msal';
import store from '@/store';
import Home from '@/views/Home.vue';
import { AccountType } from '@/interfaces/AccountType';
// import AuthService, { AuthService } from '@/msal';
// import { Container } from 'inversify';
// import container from '@/injection/Container';

// const diContainer: Container = container;
// const authService: AuthService = diContainer.get<AuthService>('AuthService');

Vue.use(VueRouter);

const ifAuthRedirect = () => {
  switch (store.getters.accountType) {
    case AccountType.Business:
      return 'business';
    case AccountType.Customer:
      return 'pay';
    default:
      return '';
  }
};

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    redirect: ifAuthRedirect,
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Pay.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/business',
    name: 'Business',
    redirect: { name: 'Dashboard' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/business/Business.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/business/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/business/Dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/business/edit',
        name: 'BusinessEdit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/business/BusinessEdit.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/business/services',
        name: 'BusinessServicesEdit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/business/ServicesEdit.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/business/receipts',
        name: 'BusinessReceipts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/business/Receipts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/business/help',
        name: 'BusinessHelp',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/business/Help.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.userAccount) {
      next();
      return;
    }
    next('/');
    // next('/unauthorised'); // Possibly redirect to page explaing no access
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRedirect)) {
    switch (store.getters.accountType) {
      case AccountType.Business:
        next('business');
        break;
      case AccountType.Customer:
        next('customer');
        break;
      default:
        next();
        break;
    }
  } else {
    next();
  }
});

export default router;
