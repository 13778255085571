












import { Component, Vue } from 'vue-property-decorator';
import NavDraw from '@/components/NavDraw.vue';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';

@Component({
  name: 'App',
  components: {
    NavDraw,
    Menu,
    Footer
  }
})
export default class App extends Vue {}
