import { GetterTree } from 'vuex';
import MutationState from '@/interfaces/MutationState';
import { AccountType } from '@/interfaces/AccountType';

const getters: GetterTree<MutationState, MutationState> = {
  isLoggedIn: state => !!state.userToken,
  authStatus: state => state.status,
  fullName: state => `${state.userAccount?.idTokenClaims['given_name']} ${state.userAccount?.idTokenClaims['family_name']}`,
  accountType: state => {
    if (state.userAccount === null || state.userAccount === undefined) return AccountType.Undefined;

    if (state.userAccount.idTokenClaims.aud === process.env.VUE_APP_BUSINESS_ID) {
      return AccountType.Business;
    }

    if (state.userAccount.idTokenClaims.aud === process.env.VUE_APP_CUSTOMER_ID) {
      return AccountType.Customer;
    }
    return AccountType.Undefined;
  }
};

export default getters;
