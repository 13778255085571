


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PayCard'
})
export default class PayCard extends Vue {
  @Prop() private title!: string;

  @Prop() private image!: string;

  @Prop() private description!: string;

  @Prop() private linkText!: string;

  @Prop() private action!: Function;
}
