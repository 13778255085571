import { CacheLocation } from 'msal';

export default {
  customerAuth: {
    clientid: 'bf895aea-c572-4d6e-8e20-dbb7d2826e2c',
    authority:
      'https://cleanersystems.b2clogin.com/tfp/cleanersystems.onmicrosoft.com/B2C_1_c_signup_signin',
    validateAuthority: false
  },
  businessAuth: {
    clientid: '3eee12b5-9093-4fbd-94cb-89bed572cbde',
    authority:
      'https://cleanersystems.b2clogin.com/tfp/cleanersystems.onmicrosoft.com/B2C_1_b_signup_signin',
    validateAuthority: false
  },
  cache: {
    storeAuthStateInCookie: false
  },
  stripe: {
    apiKey: 'pk_test_vB5Es6ZMUkN7xzfXfIpus7Ni00D26poikI'
  }
};
