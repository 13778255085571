import 'reflect-metadata';
import { Container } from 'inversify';
import { AuthService } from '@/msal/AuthService';
import CustomerAuthService from '../msal/customerAuthService';
import BusinessAuthService from '../msal/businessAuthService';

const container = new Container();
container
  .bind<AuthService>('AuthService')
  .to(CustomerAuthService)
  .whenTargetNamed('customer');
container
  .bind<AuthService>('AuthService')
  .to(BusinessAuthService)
  .whenTargetNamed('business');

export default container;
