






































































import { Component, Vue } from 'vue-property-decorator';
import { Container } from 'inversify';
import { Account, AuthError } from 'msal';
import CustomerAuthService from '../msal/customerAuthService';
import IMenuItem from '../interfaces/MenuItem';
import container from '../injection/Container';
import { AuthService } from '../msal/AuthService';
import router from '../router';

@Component
export default class Menu extends Vue {
  public custAuthService: AuthService;

  public busAuthService: AuthService;

  //   public drawer: boolean;

  private diContainer: Container = container;

  private menu = [
    { icon: 'home', title: 'Home', link: '/' },
    { icon: 'info', title: 'About', link: '/about' }
  ];

  constructor() {
    super();
    this.custAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'customer');
    this.busAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'business');
  }

  get drawer(): boolean {
    return this.$store.state.drawer.IsOpen;
  }

  set drawer(value: boolean) {
    this.$store.commit('toggleDrawer');
  }

  get menuItems(): Array<IMenuItem> {
    return this.menu;
  }

  get account(): Account {
    return this.$store.state.userAccount;
  }

  get isBusiness(): boolean {
    if (this.account === null || this.account === undefined) return false;

    return this.account.idTokenClaims.aud === process.env.VUE_APP_BUSINESS_ID;
  }

  get isCustomer(): boolean {
    if (this.account === null || this.account === undefined) return false;

    return this.account.idTokenClaims.aud === process.env.VUE_APP_CUSTOMER_ID;
  }

  async customerLogin(): Promise<void> {
    try {
      const response = await this.custAuthService.login();
      this.$store.commit('authSuccess', this.custAuthService.getAccount());
      this.$store.commit('accessToken', response.idToken.rawIdToken);

      //   console.log(`AccessToken ${JSON.stringify(response.idToken.rawIdToken)}`);
    } catch (e) {
      if (e instanceof AuthError) {
        // console.log(e);
      } else {
        throw e;
      }
    }
  }

  async businessLogin(): Promise<void> {
    try {
      const response = await this.busAuthService.login();
      this.$store.commit('authSuccess', this.busAuthService.getAccount());
      this.$store.commit('accessToken', response.idToken.rawIdToken);

      //   console.log(`AccessToken ${JSON.stringify(response.idToken.rawIdToken)}`);
    } catch (e) {
      if (e instanceof AuthError) {
        // console.log(e);
      } else {
        throw e;
      }
    }
  }

  logout(): void {
    this.custAuthService.logout();
    this.busAuthService.logout();
  }
}
