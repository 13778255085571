import * as Msal from 'msal';
import { injectable } from 'inversify';
import config from '../config';
import { AuthService } from './AuthService';

@injectable()
export default class BusinessAuthService implements AuthService {
  private app: Msal.UserAgentApplication;

  private msalConfig: Msal.Configuration = {
    auth: {
      clientId: config.businessAuth.clientid,
      authority: config.businessAuth.authority,
      validateAuthority: config.businessAuth.validateAuthority
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: config.cache.storeAuthStateInCookie
    }
  };

  private authParams: Msal.AuthenticationParameters = {
    authority: config.businessAuth.authority
  };

  constructor() {
    this.app = new Msal.UserAgentApplication(this.msalConfig);
  }

  login(): Promise<Msal.AuthResponse> {
    return this.app.loginPopup({
      redirectUri: `https://${window.location.host}`
    }); /*
    .then(
      token => {
        console.log('JWT token ' + token);
      },
      error => {
        console.log('Login error ' + error);
      }
    );
    */
  }

  logout() {
    this.app.logout();
  }

  getAccount() {
    return this.app.getAccount();
  }

  async getToken() {
    const requestParams = this.authParams;
    requestParams.account = this.getAccount();
    return this.app.acquireTokenSilent(requestParams);
  }
}
