import { MutationTree } from 'vuex';
import { Account } from 'msal';
import MutationState from '@/interfaces/MutationState';
import CustomerAddress from '@/interfaces/Address';
import Vue from 'vue';

const mutations: MutationTree<MutationState> = {
  authRequest(state, input) {
    state.status = input;
  },
  authSuccess(state, account: Account) {
    state.status = 'success';
    state.userToken = account.idToken.value;
    state.userAccount = account;
    // console.log(JSON.stringify(account));
  },
  accessToken(state, token: string) {
    state.accessToken = token;
  },
  authError(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.userToken = '';
  },
  addressUpdate(state, address: CustomerAddress) {
    if (state.address !== undefined) {
      state.address.line1 = address.line1;
      state.address.line2 = address.line2;
      state.address.city = address.city;
      state.address.county = address.county;
      state.address.postcode = address.postcode;
      //   state.address = address;
    }
  },
  updateLine1(state, input: string) {
    if (state.address !== undefined) {
      state.address.line1 = input;
    }
  },
  updateLine2(state, input: string) {
    if (state.address !== undefined) {
      state.address.line2 = input;
    }
  },
  updateCity(state, city: string) {
    if (state.address !== undefined) {
      state.address.city = city;
    }
  },
  updateCounty(state, input: string) {
    if (state.address !== undefined) {
      state.address.county = input;
    }
  },
  updatePostcode(state, input: string) {
    if (state.address !== undefined) {
      state.address.postcode = input;
    }
  },
  commitPaymentToken(state, input) {
    if (input !== null) {
      state.paymentToken = input;
    }
  },
  updateServicePrice(state, input) {
    state.servicePrice = input;
  },
  toggleDrawer(state) {
    Vue.set(state.drawer, 'IsOpen', !state.drawer.IsOpen);
  }
};

export default mutations;
