export interface Address {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postcode: string;
}

export default class CustomerAddress implements Address {
  city = '';

  county = '';

  postcode = '';

  line1 = '';

  line2 = '';
}
