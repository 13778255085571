





























import { Component, Vue } from 'vue-property-decorator';
import { Container } from 'inversify';
import { AccountType } from '../interfaces/AccountType';

@Component
export default class NavDraw extends Vue {
  public items = this.$store.state.drawer.MenuItems;

  private drawColor = 'grey darken-3';

  get isBusiness() {
    return this.$store.getters.accountType === AccountType.Business;
  }

  get miniVariant() {
    return this.$store.state.drawer.IsOpen;
  }

  get color() {
    return this.drawColor;
  }

  set color(value: string) {
    this.drawColor = value;
  }

  get fullName() {
    return this.$store.getters.fullName;
  }
}
