import { ActionTree, ActionContext } from 'vuex';
import MutationState from '@/interfaces/MutationState';
import CustomerAddress, { Address } from '@/interfaces/Address';

const actions: ActionTree<MutationState, MutationState> = {
  authRequest(context, input: string) {
    context.commit('authRequest', input);
  },
  authSuccess(context, account: Account) {
    context.commit('authSuccess', account);
  },
  accessToken(context, token: string) {
    context.commit('accessToken', token);
  },
  authError(context, input: string) {
    context.commit('authError', input);
  },
  logout(context) {
    context.commit('logout');
  },
  addressUpdate(context, address: CustomerAddress) {
    context.commit('logout', address);
  },
  updateLine1(context, input: string) {
    context.commit('updateLine1', input);
  },
  updateLine2(context, input: string) {
    context.commit('updateLine2', input);
  },
  updateCity(context, city: string) {
    context.commit('updateCity', city);
  },
  updateCounty(context, input: string) {
    context.commit('updateCounty', input);
  },
  updatePostcode(context, input: string) {
    context.commit('updatePostcode', input);
  },
  commitPaymentToken(context, input) {
    context.commit('commitPaymentToken', input);
  },
  updateServicePrice(context, input: number) {
    context.commit('updateServicePrice', input);
  }
};

export default actions;
